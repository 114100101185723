export const locales = [
  {
    label: 'English',
    value: 'en-US'
  }
]

export const defaultLocale = {
  label: 'English',
  value: 'en-US'
}

export const resources = {
  'en-US': {
    translation: {}
  }
}
