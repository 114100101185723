import { hc, type InferRequestType, type InferResponseType } from 'hono/client'
import type { SolcoreAppType } from '@turbx-libs/solcore'
import { Configuration } from '@/configuration'
// import { ensureValidJwtToken } from '../authentication'
import {
  useQuery,
  type DefaultError,
  type QueryKey,
  type SetDataOptions,
  type Updater,
  type UseQueryOptions
} from '@tanstack/react-query'
import { queryClient } from '../query'

const buildCommonHeaders = (): Record<string, string> => {
  return {
    'x-app-version': Configuration.APP_VERSION,
    'x-app-source': 'webapp'
  }
}

export const solcoreClient = hc<SolcoreAppType>(Configuration.SOLCORE_API_ENDPOINT, {
  headers: async () => {
    // const token = await ensureValidJwtToken()

    return {
      // ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
      ...buildCommonHeaders()
    }
  }
})

// https://hono.dev/docs/guides/rpc#client

const $solcoreApis = {
  // verbose
  metadata: solcoreClient.tokens.verbose
} as const

type SolcoreApi = typeof $solcoreApis

// https://hono.dev/docs/concepts/stacks#with-react
type SolcoreQueries = {
  [K in keyof SolcoreApi]: SolcoreApi[K] extends { $get: unknown } ? K : never
}[keyof SolcoreApi]

// https://github.com/orgs/honojs/discussions/3075
// https://github.com/orgs/honojs/discussions/2475#discussioncomment-9073957

const solcoreInvalidateQuery = <
  Type extends SolcoreQueries,
  Req extends InferRequestType<SolcoreApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables
) => {
  queryClient.invalidateQueries({ queryKey: [type, params] as unknown as TQueryKey })
}

const solcoreSetQueryData = <
  Type extends SolcoreQueries,
  Req extends InferRequestType<SolcoreApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<SolcoreApi[Type]['$get']>,
  TData = TQueryFnData
>(
  type: Type,
  params: TVariables,
  updater: Updater<TData | undefined, TData | undefined>,
  options?: SetDataOptions
): TData | undefined => {
  return queryClient.setQueryData<TData>([type, params] as QueryKey, updater, options)
}

const useSolcoreQuery = <
  Type extends SolcoreQueries,
  Req extends InferRequestType<SolcoreApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<SolcoreApi[Type]['$get']>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [type, params] as unknown as TQueryKey,
    queryFn: async () => {
      const res = await $solcoreApis[type].$get({ query: params } as never)
      return (await res.json()) as never
    },
    ...options
  })
}

export const solcore = {
  useQuery: useSolcoreQuery,
  invalidateQuery: solcoreInvalidateQuery,
  setQueryData: solcoreSetQueryData,
  apiClient: $solcoreApis
}

export interface SolcoreTypes {
  TokenVerbose: InferResponseType<(typeof $solcoreApis.metadata)['$get']>
}
