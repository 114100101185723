import packageJson from '../package.json'

type EnvironmentMode = 'development' | 'staging' | 'production'

interface EnvironmentVariables {
  MODE: EnvironmentMode
  ENABLE_DEBUGGER?: boolean
  SOLCORE_API_ENDPOINT: string
  SENTRY_DSN?: string
  LAMPORTS_PER_SOL: number
  APP_VERSION: string
}

const BASE: Pick<EnvironmentVariables, 'MODE' | 'APP_VERSION' | 'LAMPORTS_PER_SOL'> = {
  MODE: import.meta.env.MODE as EnvironmentMode,
  LAMPORTS_PER_SOL: 1000000000,
  APP_VERSION: packageJson.version
} as const

const CONF: {
  development: EnvironmentVariables
  staging: EnvironmentVariables
  production: EnvironmentVariables
} = {
  development: {
    ...BASE,
    SOLCORE_API_ENDPOINT: 'http://localhost:13311'
  },
  staging: {
    ...BASE,
    ENABLE_DEBUGGER: true,
    SOLCORE_API_ENDPOINT: 'https://solcore-staging.cheesebot.xyz'
  },
  production: {
    ...BASE,
    ENABLE_DEBUGGER: true,
    SOLCORE_API_ENDPOINT: 'https://solcore.cheesebot.xyz'
  }
}

const fetchConfiguration = () => {
  const mode = import.meta.env.MODE as EnvironmentMode

  if (import.meta.env.DEV)
    return (Object.keys(CONF[mode]) as Array<keyof EnvironmentVariables>).reduce((prev, cur) => {
      return {
        ...prev,
        [cur]: import.meta.env[`VITE_${cur}`] ?? CONF[mode][cur]
      }
    }, {}) as EnvironmentVariables

  return CONF[mode]
}
export const Configuration = fetchConfiguration()
